#size__text{
  font-size:15px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;

}
  
.slide__container {
    width: 250%;
   margin-left: 0%;
  }
  
  .slider__1 {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .slider__1:hover {
    opacity: 1;
  }
  
  .slider__1::-webkit-slider-thumb {
    -webkit-appearance: none ;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: rgb(184, 180, 180);
    cursor: pointer;
  }
  
  .slider__1::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04AA6D;
    cursor: pointer;
  }


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  



.dot-form {
    width: 90%;
    max-width: 500px;
}

#priceflex{
    display: flex;
}
.mn{
    display: flex;
}
.mn h2{
    color:#104E71;
    font-size: 20px;
    font-family: "Oswald";
    font-weight: 700;
    
}

@media only screen and (max-width: 900px) {
    #priceflex{
        flex-direction:column;
     }
     .mn{
        text-align: left;
        margin-left: -60%;
        margin-top: 12%;

    }
    #size__text{
      font-size:15px;
      font-family: 'Oswald';
      font-style: normal;
      font-weight: 500;
    
    }
      
    .slide__container {
        width: 250%;
       margin-left: -240%;
      }
    
    
}
@media only screen and (max-width: 800px) {
  #size__text{
    font-size:15px;
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
  
  }
    
  .slide__container {
      width: 250%;
     margin-left: -180%;
    }

}
@media only screen and (max-width: 700px) {
     .mn{
        text-align: left;
        margin-left: -50%;
        margin-top: 18%;

    }
    .mn h2{
        color:#104E71;
        font-size: 15px;
        font-family: "Oswald";
        font-weight: 700;
        margin-left: 5%;
    }   
    .switch {
        position: relative;
        display: inline-block;
        width: 84px;
        height: 34px;
      }
      .slide__container {
          width: 250%;
         margin-left: -140%;
        }
    
}

@media only screen and (max-width: 570px) {
    .mn{
       text-align: left;
       margin-left: -45%;
       margin-top: 18%;

   }
   #size__text{
    font-size:15px;
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
  
  }
    
  .slide__container {
      width: 250%;
     margin-left: -100%;
    }

   
}
@media only screen and (max-width: 480px) {
  .slide__container {
    width: 200%;
   margin-left: -50%;
  }

    .mn{
       text-align: left;
       margin-left: -35%;
       margin-top: 18%;

   }
   .mn h2{
    color:#104E71;
    font-size: 15px;
    font-family: "Oswald";
    font-weight: 700;
    margin-left: 5%;
}   
#size__text{
  font-size:12px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;

}
  
.slide__container {
    width: 250%;
   margin-left: -80%;
  }

}
@media only screen and (max-width: 350px) {
  #size__text{
    font-size:12px;
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
  
  }
    
  .slide__container {
      width: 200%;
     margin-left: -60%;
    }
}
@media only screen and (max-width: 280px) {
  #size__text{
    font-size:8px;
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
  
  }
    
  .slide__container {
      width: 150%;
     margin-left: -40%;
    }
}
@media only screen and (max-width: 260px) {
  #size__text{
    font-size:8px;
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
  
  }
    
  .slide__container {
      width: 130%;
     margin-left: -30%;
    }
}

@media only screen and (max-width: 190px) {
  #size__text{
    font-size:5px;
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
  
  }
    
  .slide__container {
      width: 100%;
     margin-left: 0%;
    }
}