body{
    overflow-x: hidden;
}
.blogctext1a a,.blogctext2a a,.blogctext1 a,.blogctext2 a,.blogctext1b a,.blogctext2b a{
    color: #104E71  !important;
}
.blogtext1{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 8vw;
    text-transform: uppercase;
    color: #104E71;
    margin-left: 8%;
    margin-top: 0%;

}
.wvbtext2{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 8vw;
    text-transform: uppercase;
    color: #104E71;
    margin-left: 8%;
    margin-top: -3%;

}

.blogtext2{
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 1.2vw;
    color: #000000;
    margin-left: 8%;


}



.blogcircle1{
    position: absolute;
    margin-top: 5%;
    width: 20%;
    margin-left: 8%;
}
.blogcircle1pic{
    position: absolute;
    margin-top: 6%;
    width: 18%;
    margin-left: 9.010%;
    border-radius: 50%;
}
.blogcentermargin{
    margin-top: 50%;
}
.blogcenter2{
    margin-top: 45%;
}
.blogctext1{
    position: absolute;
    font-family: 'Oswald';
    font-weight: 600;
    font-size: 2vw;
    text-transform: uppercase;
    color: #000000;
    margin-left: 7%;
    margin-top: 26.5%;
    text-align: center;  
}
.blogctext2{
    position: absolute;
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 1.2vw;
    color: #000000;
    margin-left: 8%;
    margin-top: 34%; 
    text-align: center; 
}
.artbtn1{
    position: relative;
    width: 40% ;
    padding: 1.3%;
    font-family: 'Oswald';
    text-align: center;
    text-transform: uppercase;
    font-size: 1.3vw;
    margin-left: 28.5%;
     border-radius: 10px;
    background: #88F2E8;
    color: #104E71;
    margin-top: 8% ;
 cursor: pointer;
    font-weight: 500;
}
.artbtn2{
    position: relative;
    width:55% ;
    padding: 1.3%;
    font-family: 'Oswald';
    text-align: center;
    text-transform: uppercase;
    font-size: 1.3vw;
    margin-left: 23.5%;
     border-radius: 10px;
    background: #88F2E8;
    color: #104E71;
    margin-top: 8% ;
 cursor: pointer;
    font-weight: 500;
}
.blogcircle1a{
    position: absolute;
    margin-top: 5%;
    width: 20%;
    margin-left: 40%;
}
.blogcircle1apic{
    position: absolute;
    margin-top: 6%;
    width: 18%;
    margin-left: 41.010%;
    border-radius: 50%;
}

.blogctext1a{
    position: absolute;
    font-family: 'Oswald';
    font-weight: 600;
    font-size: 2vw;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    margin-left: 40%;
    margin-top: 26.5%;  
}
.blogctext2a{
    position: absolute;
    text-align: center;
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 1.2vw;
    color: #000000;
    margin-left: 39.6%;
    margin-top: 34%;  
}
.blogcircle1b{
    position: absolute;
    margin-top: 5%;
    width: 20%;
    margin-left: 72%;
}
.blogcircle1bpic{
    position: absolute;
    margin-top: 6%;
    width: 18%;
    margin-left: 73.010%;
    border-radius: 50%;
}

.blogctext1b{
    position: absolute;
    font-family: 'Oswald';
    font-weight: 600;
    font-size: 2vw;
    text-transform: uppercase;
    color: #000000;
    margin-left: 74.3%;
    margin-top: 26.5%;  
    text-align: center;
}
.blogctext2b{
    position: absolute;
    font-family: 'Oswald';
text-align: center;
    font-weight: 400;
    font-size: 1.2vw;
    color: #000000;
    margin-left: 74.7%;
    margin-top: 34%;  
}
.blogtext2mb{
    visibility: hidden;
}
#bmargin{
    margin-top: 97%;
}
#bmargin2{
    margin-left: 8.5%;

}
#bmargin3{
    margin-left: 7.5%;

}
#bmargin4{
    margin-left: 42.7%;

}
#bmargin5{
    margin-left: 39%;

}
@media only screen and (max-width: 750px) {
    .blogtext1{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 13vw;
        text-transform: uppercase;
        color: #104E71;
        margin-left: 5%;
        margin-top: -20%;
    
    }
    
    .blogtext2{
        display: none;
    }
    .blogtext2mb{
        font-family: 'Oswald';
        font-weight: 400;
        font-size: 3.6vw;
        color: #000000;
        margin-left: 5%;

    margin-bottom: -4%;
    visibility:visible

    }
    
    
    .blogcircle1{
        position: relative;
        margin-top: 5%;
        width: 80%;
        margin-left: 10%;
    }
    .blogcircle1pic{
        position: absolute;
        margin-top: 8.8%;
        width: 73%;
        margin-left: -76.5%;
        border-radius: 50%;
    }
    .blogcentermargin{
        margin-top: 8%;
    }
    .blogctext1{
        position: relative;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 6vw;
        text-transform: uppercase;
        color: #000000;
        margin-left: 0%;
        margin-top: 3%;
        text-align: center;  
    }
    .blogctext2{
        position: relative;
        font-family: 'Oswald';
        font-weight: 400;
        font-size: 3vw;
         color: #000000;
        margin-left: 0%;
        text-align: center;
        margin-top: 3%;  
    }
    .blogcircle1a{
        position: relative;
        margin-top: 5%;
        width: 80%;
        margin-left: 10%;
    }
    .blogcircle1apic{
        position: absolute;
        margin-top: 8.8%;
        width: 73%;
        margin-left: -76.5%;
        border-radius: 50%;
    }
    
    .blogctext1a{
        position: relative;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 8vw;
        text-transform: uppercase;
        color: #000000;
        margin-left: 0%;
        margin-top: 3%;
        text-align: center;  
    }
    .blogctext2a{
        position: relative;
        font-family: 'Oswald';
        font-weight: 400;
        font-size: 3vw;
        color: #000000;
        margin-left: 0%;
        text-align: center;
        margin-top: 3%;  
    }
    .blogcircle1b{
        position: relative;
        margin-top: 5%;
        width: 80%;
        margin-left: 10%;
    }
    .blogcircle1bpic{
        position: absolute;
        margin-top: 8.8%;
        width: 73%;
        margin-left: -76.5%;
        border-radius: 50%;
    }
    
    .blogctext1b{
        position: relative;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 8vw;
        text-transform: uppercase;
        color: #000000;
        margin-left: 0%;
        margin-top: 3%;
        text-align: center;  
    }
    .blogctext2b{
        position: relative;
        font-family: 'Oswald';
        font-weight: 400;
        font-size: 3vw;
        color: #000000;
        margin-left: 0%;
        text-align: center;
        margin-top: 3%;  
    }
    .artbtn1{
        position: relative;
        width: 40% ;
        padding: 1.3%;
        font-family: 'Oswald';
        text-align: center;
        text-transform: uppercase;
        font-size: 3vw;
        margin-left: 29%;
         border-radius: 10px;
        background: #88F2E8;
        color: #104E71;
        margin-top: 6% ;
     cursor: pointer;
        font-weight: 500;
    }
    .artbtn2{
        position: relative;
        width: 40% ;
        padding: 1.3%;
        font-family: 'Oswald';
        text-align: center;
        text-transform: uppercase;
        font-size: 3vw;
        margin-left: 29%;
         border-radius: 10px;
        background: #88F2E8;
        color: #104E71;
        margin-top: 6% ;
     cursor: pointer;
        font-weight: 500;
    }
    .blogcenter2{
        margin-top: 5%;
    }
    #bmargin{
        margin-top: 0%;
    }
    #bmargin2{
        margin-left: 0%;
    
    }
    #bmargin3{
        margin-left: 0%;
    
    }
    #bmargin4{
        margin-left: 0%;
    
    }
    #bmargin5{
        margin-left: 0%;
    
    }
}