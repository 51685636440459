.modal-xl{
    min-width: 103.4% !important;
margin-top: -.3% !important;
margin-left: -1% !important;
}
#closebtn2{
    color: #104E71;
    margin-left: 5%;
margin-top: 3%;
font-size: 3.5vw;
opacity: 1;
float: left;

text-decoration: none !important;
}
#closebtn{
    font-size: 0vw;
    display: none;
}

.ecircle1{
    position: absolute;
    width: 32%;
    margin-top: -5%;
    margin-left: 61%;
}
.esmale1{
    position: absolute;
    width: 28%;
    margin-top: -3%;
    margin-left: 63.1%;
}
.esmaintx1{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 5vw;
    text-transform: uppercase;
    color: #104E71;
    margin-left: 8%;

}
.esmaintx2{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 2.9vw;
    text-transform: uppercase;
    color: #104E71;
    margin-left: 1.7%;

}
.esmaintx3{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 2.9vw;
    text-transform: uppercase;
    color: #104E71;
    margin-left: 8%;

}
.esmaintx4{
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 1.2vw;
    color: #000000;
    margin-left: 8%;
    margin-top: 1%;
    margin-bottom: 2%;

}
.esmaintx4mb{
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 0vw;
    color: #000000;
    margin-left: 0%;
    margin-top: 0%;

}
.esline1{
    margin-top: 10%;
    border: 1px solid #077F8D;
    width: 90%;
}
.escircle2{
position: absolute;
width: 12%;
margin-left: 82%;
}
.esph1{
    position: absolute;
    width: 11%;
    margin-left: 82.3%;
    margin-top: 3.20%;
}
    
.escircle4{
    position: absolute;
    width: 9%;
    margin-left: 63%;
    margin-top: 5%;
}

.esph2{
    position: absolute;
    width: 7%;
    margin-left: 63.8%;
    margin-top: 6.8%;
}
.escircle3{
    position: absolute;
    width: 15%;
    margin-left: 71%;
    margin-top: 18%;
}
.esph3{
    position: absolute;
    width: 12%;
    margin-left: 72.20%;
    margin-top: 21%;
}
.esc1tx1{
    margin-top: 5%;
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 3.2vw;
    text-transform: uppercase;
    color: #104E71;
    margin-left: 8%;
}
.esc1tx2{
    margin-top: 3%;
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 2vw;
    text-transform: uppercase;
    color: #077F8D;
        margin-left: 8%;
}
.esc1tx3{
    margin-top: -.7%;
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 1.2vw;
    color: #000000;
    margin-left: 8%;
    
}
@media (max-width:750px) {
    .ecircle1{
        position: absolute;
        width: 0%;
        margin-top: -5%;
        margin-left: 61%;
    }
    .esmale1{
        position: absolute;
        width: 0%;
        margin-top: -3%;
        margin-left: 63.1%;
    }
    .esmaintx1{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 10vw;
        text-transform: uppercase;
        color: #104E71;
        margin-left: 5%;
    }
    .esmaintx2{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 5.5vw;
        text-transform: uppercase;
        color: #104E71;
        margin-left: 2%;
    }
    .esmaintx3{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 5.5vw;

        text-transform: uppercase;
        color: #104E71;
        margin-left: 5%;
    }
    .esmaintx4{
        font-family: 'Oswald';
        font-weight: 400;
        font-size: 0vw;
        color: #000000;
        margin-left: 0%;
        margin-top: 0%;
    
    }
    .esmaintx4mb{
        font-family: 'Oswald';
        font-weight: 400;
        font-size: 4vw;
        color: #000000;
        margin-left: 5%;
    
    }
    .esline1{
        margin-top: 7%;
        border: 1px solid #077F8D;
        width: 100%;
    }
    .escircle2{
    position: absolute;
    width: 40%;
    margin-left: 54%;
    margin-top: 8%;

    }
    .esph1{
        position: absolute;
        width: 36%;
        margin-left: 55.3%;
        margin-top: 19%;
    }
        
    .escircle4{
        position: relative;
        width: 28%;
        margin-left: 5%;
        margin-top: 17%;
        margin-bottom: 55%;
    }
    
    .esph2{
        position: absolute;
        width: 24%;
        margin-left: -27%;
        margin-top: 22%;
    }
    .escircle3{
        position: relative;
        width: 48%;
        margin-left: -11%;
        margin-top: 50.5%;
    }
    .esph3{
        position: absolute;
        width: 37%;
        margin-left: -43%;
        margin-top: 61.3%;
    }
    .esc1tx1{
        margin-top: 0%;
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 0vw;
        text-transform: uppercase;
        color: #104E71;
        margin-left: 0%;
    }
    .esc1tx2{
        margin-top: 0%;
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 0vw;
        text-transform: uppercase;
        color: #104E71;
        margin-left: 0%;
    }
    .esc1tx3{
        margin-top: 0%;
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 0vw;
        text-transform: uppercase;
        color: #104E71;
        margin-left: 0%;
    }
    .modal-xl{
        min-width: 110% !important;
    margin-top: -.5% !important;
    margin-left: -3% !important;
    }
    #closebtn2{
     display: none;
    }
    #closebtn{
        color: #104E71;
        margin-left: 92%;
    margin-top: -.3%;
    float: left;
    font-size: 30px;
    opacity: 1;
display: block;

    text-decoration: none;
    }
    
}