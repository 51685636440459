body{
    width: 100%;
    overflow-x: hidden;
}
.pmcenter1{
margin-top: 0%;
}
.pmc1txt1{
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 4.3vw;
    color: #104E71;
    text-align: center;
}
.pmc1txt2{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 1.4vw;
    color: #104E71;
    margin-left: 13.8%;
}
.pmc1txt3{
    margin-right: 62%;
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 1.1vw;
    color: #077F8D;
    text-align: center;
}
hr.pmc1line1{
    position: absolute;
    width: 9%;
    border: 1px solid #104E71;
    transform: rotate(90deg);
margin-top: 16%;
margin-left: 31%;

}
hr.pmc1line2{

    width: 90%;
    border: 1px solid #104E71;
    margin-top: 5%;
    margin-left: 5%;

}
.pmc1txt2mb,.pmc1txt3mb{
display: none;
}

hr.pmc1line2mbb{

    width: 0%;
    border: 0px solid #104E71;
    margin-top: 0%;
    margin-left: 0%;

}

@media only screen and (max-width: 750px) {
.pmc1txt2,.pmc1txt3{
    display: none;
}
hr.pmc1line1{
display: none;

}

.pmc1txt1{
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 9.5vw;
    color: #104E71;
    text-align: center;
}
.pmc1txt2mb{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 4,5vw;
    color: #104E71;
    text-align: center;
    display: block;
    }
.pmc1txt3mb{
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 3vw;
    color: #077F8D;
    text-align: center;
    display: block;
}
hr.pmc1line2mbb{

    width: 90%;
    border: 1px solid #104E71;
    margin-top: 5%;
    margin-left: 5%;
    display: block;
}
hr.pmc1line2{

    width: 100%;
    border: 1px solid #104E71;
    margin-top: 5%;
    margin-left: 0%;

}
}