#log__con__head{
width: 15%;
}
.headerlogo2{
    float: left;
    width: 6%;
margin-left: 10%;
margin-top: 2%;
}
.linkheader2{
    margin-top: 3.2%;
    float: left;
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 1.3vw;
    color: #104E71;
    margin-left: 10%;
    text-decoration: none !important;
}

.dropdown {
    position: relative;

  }
  
  .dropdown-contenth2 {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: 12%;
    margin-top: 3.3%;
  }
  
  .dropdown-contenth2 a {
    color: #104E71;
    padding: 8% 6%;
    text-decoration: none;
    display: block;

    font-family: 'Oswald';
   font-size: 1.2vw;
    font-weight: 300;

  }
  
  .dropdown-contenth2 a:hover {background-color: #f1f1f1;text-decoration: none;color: black;}
  
  .dropdown:hover .dropdown-contenth2 {
    display: block;
  }
  
  
  .dropdown-contenth3 {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: 46.5%;
    margin-top: 4.8%;
  }
  
  .dropdown-contenth3 a {
    color: #104E71;
    padding: 8% 6%;
    text-decoration: none;
    display: block;

    font-family: 'Oswald';
   font-size: 1.2vw;
    font-weight: 300;

  }
  
  .dropdown-contenth3 a:hover {background-color: #f1f1f1;text-decoration: none;color: black;}
  
  .dropdown:hover .dropdown-contenth3 {
    display: block;
  }
  
  
  .gettext{
    float: left;
    margin-left: 4%;
    margin-top: 3%;
    width: 10%;
    text-align: center;
    background: #104E71;
    border-radius: 5px;
    color: #FFFFFF;
    font-family: 'Oswald';
    font-size: 1.2vw;
    padding: .4%;
    cursor: pointer;
  }

  @media only screen and (max-width: 750px) {
 
    .linkheader2{
      margin-top: 3.2%;
      float: left;
      font-family: 'Oswald';
      font-weight: 500;
      font-size: 0vw;
      color: #104E71;
      margin-left: 10%;
      text-decoration: none !important;
  }
  }

  @media only screen and (max-width: 450px) {
    #log__con__head{
      width: 25%;
      }
  }
