
  
  .dropdown {
    position: relative;

  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: 18%;
    margin-top: 3.5%;
  }
  
  .dropdown-content a {
    color: black;
    padding: 8% 6%;
    text-decoration: none;
    display: block;

    font-family: 'Oswald';
   font-size: 1.2vw;
    font-weight: 300;

  }
  
  .dropdown-content a:hover {background-color: #f1f1f1;text-decoration: none;color: black;}
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }
  .dropdown-contentb {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: 35%;
    margin-top: 3.4%;
  }
  
  .dropdown-contentb a {
    color: black;
    padding: 8% 6%;
    text-decoration: none;
    display: block;

    font-family: 'Oswald';
    font-size: 1.2vw;
    font-weight: 300;

  }
  
  .dropdown-contentb a:hover {background-color: #f1f1f1;text-decoration: none;color: black;}
  
  .dropdown:hover .dropdown-contentb {
    display: block;
  }
  