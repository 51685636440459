@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');
body{
    overflow-x: hidden;

}
.main__con{
    z-index: 999;
}
.contactheader2{
    position: relative;
}
.threephones2{
 width: 100%;
}
.rectangleblue2{
    position: absolute;
    width: 108%;
   margin-left: -99%;
   opacity: 0.5;    
}
.elpseleft2{
    position: absolute;
    margin-left: -13%;
    width: 13%;
}
.elpseright2{
    position: absolute;
    margin-top: -4%;
    margin-left: -100%;
    width: 20%;
}
.gettouch2{
    position: absolute;
   margin-top: -17%;
    margin-left:38.3%;
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 4.3vw;
    font-style: normal;
    text-align: center;
    color: #FFFFFF;
}
.querytext2{
   font-family: 'Oswald';
    font-weight: 500;
    font-size: 2.3vw;
    font-style: normal;
    text-align: center;
   margin-top: 5%;
    color: #104E71;
 }
 .contactform2{
    margin-top: 5%;
    
 }
 .leftsideform2{
    float: left;
    margin-left: 5%;
    width: 45%;
 }
 .formtext12{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 1vw;
    font-style: normal;
margin-left: -60%;
   color: #AB98BA;
   margin-top: 5%;
   text-align: left;
   margin-bottom: 2%;
 }
 .formtext12c{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 1vw;
    font-style: normal;
margin-left: 0%;
   color: #AB98BA;
   margin-top: 5%;
   text-align: left;
   margin-bottom: 2%;
 }
 .inp2{
    width: 70%;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid rgba(171, 152, 186, 0.5);
    margin-top: -12%;
    padding: 1%;

 }
 
 
 .inp2:hover{
    border: 1px solid rgba(171, 152, 186, 1);
    
  }
  input[type=text]:focus {
    border: 2px solid rgba(171, 152, 186, 1) !important;
    outline: none;

}
  
  
 .rightsideform2{
    float: left;
    margin-left: 0%;
    width: 45%;
 }
 .formcontrol2{

    margin-top: 7.5% !important;     
    background: #FFFFFF !important;
    border: 1px solid rgba(171, 152, 186, 0.5) !important;
    border-radius: 5px !important;
    padding: 1%;
    font-family: 'Oswald' !important;
    font-weight: 400 !important;
    font-size: 1.2vw !important;
    font-style: normal;
     color: #AB98BA !important;
     width: 100%;
}
.formcontrol2:focus {
    border: 2px solid rgba(171, 152, 186, 1) !important;
    outline: none !important;

}
.formcontrol2:hover{
    border: 2px solid rgba(171, 152, 186, 1);
    
  }
 
.placeholder2{
    color: #AB98BA ;


}
.submitbtn2{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 1.2vw;
    text-align: center;
    color: #FFFFFF;
    align-items: center;
    background: #104E71;
    border-radius: 10px;
    width: 10%;
    float: right;
    margin-right: 5%;
 padding:.8%;
 margin-top: 2%;

}
.contactlast2{
    margin-top: 4%;
}
.conlastleft2{
    float: left;
    width: 40%;

}
.conltext12{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 2vw;
    text-align: center;
    color: #104E71;
}
.address2{
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 1.2vw;
    text-align: center;
    color: #104E71;
    text-transform: uppercase;

}
#ad{
    margin-left: 42%;
}
hr.conline2{
    border: 1px solid #104E71;
    width: 7%;
}
.conlastright2{
    float: left;
    width: 60%;
}

.followtext2{
    margin-left: 62.5%;
    font-family: 'Oswald';
    font-weight: 500;
    font-size:2vw;
    color: #104E71;
}
.mapcon2{
    margin-left: 17%;
    width: 75%;
    height: 50vh;
    margin-bottom: 10%;
}
#em3{
    margin-left: "-196%";
}
.querytextmb2{
    display: none;
}
#add__2{
    margin-left: 42%;
}
@media (max-width:750px) {
    #add__2{
        margin-left: 0%;
    }
    .querytext2{
        display: none;
    }
    .querytextmb2{
        font-family: 'Oswald';
         font-weight: 500;
         font-size: 5vw;
         font-style: normal;
         text-align: center;
        margin-top: 5%;
         color: #104E71;
         display: block;
      }
      .contactform2{
         margin-top: 0%;
         
      }
      .leftsideform2{
         float: left;
         margin-left: 5%;
         width: 100%;
      }
      .formtext12{
         font-family: 'Oswald';
         font-weight: 500;
         font-size: 2.5vw;
         font-style: normal;
         text-align: center;
        color: #AB98BA;
        margin-top: 5%;
        margin-left: -80%;
      }
      .formtext12c{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 2.5vw;
        font-style: normal;
        text-align: center;
       color: #AB98BA;
       margin-top: 5%;
       margin-left: -85%;
     }
      .inp2{
         width: 80%;
         background: #FFFFFF;
         border-radius: 5px;
         border: 1px solid rgba(171, 152, 186, 0.5);
         margin-top: -12%;
         padding: 1%;
         margin-left: -13%;
      }
      .rightsideform2{
         float: left;
         margin-left: 6%;
         width: 80%;
      }
      .formcontrol2{
     
         margin-top: 7.5% !important;     
         background: #FFFFFF !important;
         border: 1px solid rgba(171, 152, 186, 0.5) !important;
         border-radius: 5px !important;
         margin-left: 3%;
         font-family: 'Oswald' !important;
         font-weight: 400 !important;
         font-size: 2.5vw !important;
         font-style: normal;
          color: #AB98BA !important;
     }
     .placeholder2{
         color: #AB98BA ;
     
     }
     .submitbtn2{
         font-family: 'Inter', sans-serif;
         font-weight: 500;
         font-size: 3vw;
         text-align: center;
         color: #FFFFFF;
         align-items: center;
         background: #104E71;
         border-radius: 10px;
         width: 25%;
         float: right;
         margin-right: 38%;
      padding:1.5%;
      margin-top: 4%;
      cursor: pointer;
     }
     .contactlast2{
         margin-top: 4%;
     }
     .conlastleft2{
         float: left;
         width: 100%;
     
     }
     .conltext12{
         font-family: 'Oswald';
         font-weight: 500;
         font-size: 5vw;
         text-align: center;
         color: #104E71;
     }
     .address2{
        font-family: 'Oswald';
        font-weight: 400;
        font-size: 3vw;
        text-align: center;
        color: #104E71;

        text-transform: uppercase;
    
     
     }
     
     hr.conline2{
         border: 1px solid #104E71;
         width: 7%;
     }
     .conlastright2{
         float: left;
         width: 100%;
         margin-bottom: 5%;
         margin-left: 0%;
     }
     
     .followtext2{
         margin-left: 62.5%;
         font-family: 'Oswald';
         font-weight: 500;
         font-size:2vw;
         color: #104E71;
     }
     .mapcon2{
        margin-left: 0%;
        width: 80%;
        height: 50vh;
    }
    .rectangleblue2{
        position: absolute;
        width: 100%;
       margin-left: -90%;
       opacity: 0.5;    
    }
    .threephones2{
        width: 0%;
       }
       .rectangleblue2{
           position: absolute;
           width: 0%;
          margin-left: -99%;
          opacity: 0.5;    
       }
       .elpseleft2{
           position: absolute;
           margin-left: -13%;
           width: 0%;
       }
       .elpseright2{
           position: absolute;
           margin-top: -4%;
           margin-left: -100%;
           width: 0%;
       }
       .gettouch2{
           position: absolute;
          margin-top: -17%;
           margin-left:38.3%;
           font-family: 'Oswald';
           font-weight: 400;
           font-size: 4.3vw;
           font-style: normal;
           text-align: center;
           color: #FFFFFF;
       }
       #ad{
        margin-left: 0%;
    }

}
