.feature{
    width: 80%;
    margin: auto;
    text-align: center;
    padding-top: 50px;
}
.row{
    margin-top: 5%;
        display: flex;
       
}
.row2{
    margin-top: 5%;
        display: flex;
       justify-content: space-between;
      
    }
.feature-col1{
    flex-basis:40%;
    border-radius: 10px;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
   
}
.brder{
    border: 2px solid #104E71;
    border-radius: 10%;
padding: 4%;
}
.feature-col2{
    flex-basis:60%;
    border-radius: 10px;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
   
}

.feature-col1 img{
    width: 100%;
    display: block;
    border-radius: 10%;
}
.hero-btn{
    display: inline-block;   
    color: #fff;
    text-decoration: none;
    border:  1px solid #fff;
    padding: 2% 4.5%;
    font-size: 1vw;
    background:rgba(18, 73, 109, 1);
    cursor: pointer;
    font-family: 'Oswald';
   align-items: left;
   margin-right: 55%;
   border-radius: 10px;
}
.hero-btn:hover{
    background:  rgba(18, 73, 109, 0.8);
    transition: 1s;
    color: #fff !important;
}
.feature-col2 h3{
    text-align: left;
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 3vw;
    
    text-transform: uppercase;
    color: #104E71;
    margin-left: 12%;
    margin-top: 10px;
    
}
.feature-col2 p{
    text-align: left;
    font-family: 'Oswald';
    font-weight: 300;
    font-size: 1.4vw;
    
    margin-left: 12%;
    margin-top: 10px;
    
}
.allblogs{
    width: 80%;
    margin: auto;
    text-align: center;
    padding-top: 0px;
}
.allblogs-col-m{
    flex-basis:32%;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
   
}
.allblogs-col{
    flex-basis:32%;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
   
}
.allblogs-col img{
    width: 100%;
    display: block;
}
.layer{
        height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 1s;
    

}
.layer:hover{
    background: rgba(0, 136, 226, 0.7);

}
.layer a{
    width: 100%;
    font-weight: 500;
    color: black;
    font-size: 26px;
    bottom: 0;
    font-family: 'Oswald';

    left: 50%;
position: absolute;
transform: translateX(-50%);
opacity: 0;
transition: 0.5s;
}

.layer:hover a{
    bottom: 40%;
    opacity: 1;
    color: #fff !important;
}
.alltext{
    text-align: left;
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 24px;
margin: auto;
width: 100%;
    color: #104E71;
margin-top: 2%;
transition: .5s;
  
}
.alltext:hover{
    color: rgba(33, 94, 134, 0.8) !important;
}
@media (max-width:750px) {
    .row2{
        flex-direction: column;
    }
    .feature{
display: none;
    } 
    .allblogtext hr{
        width:  0%;
        border: 0px solid #077F8D;

    }
    .allblogtext h2{
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        text-transform: uppercase; 
        margin: auto;
        color: #104E71;

    }
}
    .allblogtext{
        display: flex;
        margin-top: 3%;
        padding: 3% 6%;
        justify-content: space-between;
        align-items: center;
    }
    .allblogtext h2{
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        text-transform: uppercase; 
        margin: auto;
        color: #104E71;

    }
    .allblogtext hr{
        width: 70%;
        border: 1px solid #077F8D;

    }
    @media (max-width:750px) {
        .allblogtext hr{
            width:  0%;
            border: 0px solid #077F8D;
    
        }
        .allblogtext h2{
            font-family: 'Oswald';
            font-style: normal;
            font-weight: 500;
            font-size: 48px;
            text-transform: uppercase; 
            margin: auto;
            width: 100%;
            color: #104E71;
           text-align: center;
        }
        .layer:hover a{
            bottom: 40%;
            opacity: 1;
            color: #fff !important;
        }
    }
     

    .layer2{
        height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 1s;
    
    border-radius: 10%;
}
.layer2:hover{
    background: rgba(0, 136, 226, 0.7);

}
.layer2 a{
    width: 100%;
    font-weight: 500;
    color: #fff !important;
    font-size: 2vw;
    bottom: 0;
    font-family: 'Oswald';

    left: 50%;
position: absolute;
transform: translateX(-50%);
opacity: 0;
transition: 0.5s;
}
.layer2:hover a{
    bottom: 40%;
    opacity: 1;
    color: #fff !important;
}
.touch{
    display: none;
}
@media (max-width:750px) {
     
    .touch{
        align-items: center;
        margin-right: 20%;
        margin-top: 10%;
        background: rgba(226, 229, 231, 1);
        width: 28%;
        margin-left: 65%;
      border-radius: 5px;
    display: block;  
    }
   .layer p{
    color: rgb(19, 17, 17);
    font-size: 4vw;
    padding: 2%;
    font-weight: 600;
    font-family: 'Oswald';

} 
#ico{
    font-size: 3vw !important;
    color: rgb(19, 17, 17);
    font-weight: 600;
    margin-left: 2%;
    
 }
 
}