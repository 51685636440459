.centersecond{

}
.logodarkblue1{
    position: absolute;
    margin-top: 2%;
    margin-left: 55%;
    width: 0%;
}
.skylogo{
    float: right;
    width: 30%;
}
.skylogomb{
    float: none;
    width: 0%;
  margin-left: 0%;
}
.phonefirst{
    position: absolute;
    width: 0%;

}
.phonefirstmb{
    position: absolute;
    width: 0%;
    margin-left: -34%;
    margin-top: 6%;
}
.phonefirstmb222{
    position: absolute;
    width: 25%;
    margin-left: 23%;
    margin-top: 2%;
}
.technologytext1mb,.technologytext2mb,.seetextmb{
    float: left;
margin-top: 0%;
margin-left: 0%;
font-family: 'Oswald';
font-weight: 400;
font-size: 0vw;
color: #0E5673;
}
.technologytext1{
    float: left;
margin-top: 10%;
margin-left: 5%;
font-family: 'Oswald';
font-weight: 400;
font-size: 2.5vw;
color: #0E5673;
}
.technologytext2{
    float: left;
    margin-top: 12.6%;
margin-left: -20%;
font-family: 'Oswald';
font-weight: 400;
font-size: 3.3vw;
color: #104D73;}
.loretext2{
    float: left;
    margin-top: 17.5%;
margin-left: -27.5%;
font-family: 'Oswald';
font-weight: 300;
font-size: 1.2vw;
color: #104D73;
}
.loretext2mb{
    font-size: 0px;
}

.seetext{
    float: left;
    margin-top: 21.5%;
margin-left: -39.8%;
font-family: 'Oswald';
font-weight: 500;
font-size: 1.4vw;
color: #173649;
}
.linemain1{
    float: left;
    width: 9%;
    margin-top: 24%;
    margin-left: -39.8%;
    border: 2px solid #88F2E8;

}
.linemain1mb{
    float: left;
    width: 0%;
    margin-top: 0%;
    margin-left: 0%;
    border: 0px solid #88F2E8;

}

.circleblue{
    clear: both;  display: block; position: relative; 
 margin-left: 39%;
 width: 10%;
}
.skylogo2{
    width: 30%;
}
.phone1{
    position: absolute;
    width: 25%;
    margin-left: -29%;
    margin-top: 4.8%;
}
.geartext{
    float: right;
    margin-right: 31.3%;
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 2.7vw;
    margin-top: 6%;
    color: #104D73;
}
.loretext3{
    float: right;
    margin-top: -23.3%;
margin-right:12%;
font-family: 'Oswald';
font-weight: 300;
font-size: 1.2vw;
color: #104D73;
}
.dealtext{
    float: right;
    margin-top: -19.3%;
margin-right:37.4%;
font-family: 'Oswald';
font-weight: 500;
font-size: 1.6vw;
color: #173649;
cursor: pointer;
}
.arrow1{
    color: #0E5673;
    font-size: 1.8vw;
    
}
.linemain2{
    float: right;
    width: 9%;
    margin-top: -16.2%;
    margin-right: 38.1%;
    border: 2px solid #88F2E8;

}
.logodarkblue2{
    width: 6%;
    margin-top: 23%;
    margin-left: 3%;
}
.paragraphs{
    float: left;
    width: 70%;
}
.context1{
    margin-top: 10%;
    margin-left: 14%;
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 2.8vw;
    color: #104D73;

}
.lorecon{
margin-top: -2%;    
margin-left:14%;
font-family: 'Oswald';
font-weight: 300;
font-size: 1.2vw;
color: #104D73;
}
.startedbutton{
    font-family: 'Oswald';
    width: 14%;
    margin-left: 14%;
    font-weight: 400;
    font-size: 1.2vw;
    text-align: center;
    color: #173649;
    background: #88F2E8;
    border-radius: 8px;

}
.phone2{
    float: right;
    width: 30%;
}
.logodarkblue3{
    position: absolute;
    width: 6%;
    margin-top: 35%;
    margin-left: 28%;
}
.circleblue2{
    width: 12%;
    margin-top: 5%;
    margin-left: 50%;
}
.clearfloat{
    clear: both;  display: block; position: relative; 

}
.taketext{

    margin-top: 7%;
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    font-size: 4.2vw;
    color: #173649;
    text-align: center;

}
.taketextmb{

    margin-top: 0%;
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
    font-size: 0vw;
    color: #104E71;
    text-align: center;

}
.bxlast{
    float: left;
    width: 38%;
    margin-top: 2%;
}
.bxtxt1{
    margin-left: 22%;
    font-family: 'Oswald';
    font-style: normal;
    font-size: 1.9vw;
    font-weight: 400;
    color: #104D73;
}
.bxtxt1amb{
    margin-left: 0%;
    font-family: 'Oswald';
    font-style: normal;
    font-size: 0px;
    margin-top: -1%;

}
.bxtxt2{
    margin-top: -1%;
    margin-left: 22%;
    font-family: 'Oswald';
    font-style: normal;
    font-size: 1.2vw;
    font-weight: 300;
    color: #104D73;
}
.bxlast2{
    float: left;
    width: 30%;
    margin-top: 2%;
}
.bxtxt1a{
    margin-left: 2.5%;
    font-family: 'Oswald';
    font-style: normal;
    font-size: 1.9vw;
    font-weight: 400;
    color: #104D73;
}
.bxtxt2b{
    margin-top: -4%;
    margin-left: 2.5%;
    font-family: 'Oswald';
    font-style: normal;
    font-size: 1.2vw;
    font-weight: 300;
    color: #104D73;
}
.bxtxt1c{
    margin-left: -2%;
    font-family: 'Oswald';
    font-style: normal;
    font-size: 1.9vw;
    font-weight: 400;
    color: #104D73;
}
.bxtxt2d{
    margin-top: -2%;
    margin-left: -2%;
    font-family: 'Oswald';
    font-style: normal;
    font-size: 1.2vw;
    font-weight: 300;
    color: #104D73;
}

@media only screen and (max-width: 750px) {
    .logodarkblue1{
        
           width: 0%;
    }
    .skylogo{
        float: none;
        width: 0%;
    }
    .phonefirstmb{
        position: absolute;
        width: 30%;
        margin-left: 34%;
        margin-top: 6%;
        height: 1.62%;
    }
    .skylogomb{
        float: none;
        width: 35%;
      margin-left: 33%;
      height: .5%;
    }
    .technologytext1mb{
        float: none;
    margin-top: 4%;
    margin-left: 5%;
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 8vw;
    color: #0E5673;
    }
    .technologytext1,.technologytext2{
        float: none;
    margin-top: 0%;
    margin-left: 0%;
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 0vw;
    color: #0E5673;
    }
        .technologytext2mb{
        float: none;
        margin-top: 0%;
    margin-left: 5%;
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 0vw;
    color: #104D73;}
    
    .loretext2{
        font-size: 0px;
    }
    .loretext2mb{
        float: none;
        margin-top: -7%;
    margin-left:5%;
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 3vw;
    color: #000000;
    }
    .seetextmb{
        float: none;
        margin-top: -2.6%;
    margin-left: 5%;
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 3vw;
    color: #173649;
    }
    .linemain1mb{
        float: none;
        width: 17%;
        margin-top: -2%;
        margin-left: 5%;
        border: 2px solid #88F2E8;
    
    }
    .circleblue{
        clear: both;  display: block; position: relative; 
     margin-left: 0%;
     width: 0%;
    }

.skylogo2{
    width: 0%;
}
.phone1{
    position: absolute;
    width: 0%;
    margin-left: 0%;
    margin-top: 0%;
}
.geartext{
    float: right;
    margin-right: 0%;
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 0vw;
    margin-top: 0%;
    color: #104D73;
}
.loretext3{
    float: right;
    margin-top: 0%;
margin-right:12%;
font-family: 'Oswald';
font-weight: 300;
font-size: 0vw;
color: #104D73;
}
.dealtext{
    float: right;
    margin-top: 0%;
margin-right:0%;
font-family: 'Oswald';
font-weight: 500;
font-size: 0vw;
color: #173649;
cursor: pointer;
}
.arrow1{
    color: #0E5673;
    font-size: 0vw;
    
}
.linemain2{
    float: right;
    width: 0%;
    margin-top: 0%;
    margin-right: 0%;
    border: 0px solid #88F2E8;

}
.logodarkblue2{
    width: 0%;
    margin-top: 0%;
    margin-left: 0%;
}
.paragraphs{
    float: left;
    width: 0%;
}
.context1{
    margin-top: 0%;
    margin-left: 0%;
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 0vw;
    color: #104D73;

}
.lorecon{
margin-top: 0%;    
margin-left:0%;
font-family: 'Oswald';
font-weight: 300;
font-size: 0vw;
color: #104D73;
}
.startedbutton{
    font-family: 'Oswald';
    width: 0%;
    margin-left: 14%;
    font-weight: 400;
    font-size: 0vw;
    text-align: center;
    color: #173649;
    background: #88F2E8;
    border-radius: 8px;
  
}
.phone2{
    float: right;
    width: 0%;
    margin-top: 0%;
}
.logodarkblue3{
    position: absolute;
    width: 0%;
    margin-top: 0%;
    margin-left: 0%;
} 

.taketext{

    margin-top: 7%;
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    font-size: 0vw;
    color: #173649;
    text-align: center;

}

.taketextmb{

    margin-top: 7%;
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
    font-size: 10vw;
    color: #104E71;
    text-align: center;

}
.bxlast{
    float: left;
    width: 100%;
    margin-top: 2%;
}
.bxtxt1{
    margin-left: 5%;
    font-family: 'Oswald';
    font-style: normal;
    font-size: 8vw;
    font-weight: 400;
    color: #104E71;
}
.bxtxt2{
    margin-top: -3.4%;
    margin-left: 5%;
    font-family: 'Oswald';
    font-style: normal;
    font-size: 3.8vw;
    font-weight: 400;
    color: #104D73;
}
.bxlast2{
    float: left;
    width: 100%;
    margin-top: 2%;
}
.bxtxt1a{
    margin-left: 0%;
    font-family: 'Oswald';
    font-style: normal;
    font-size: 0vw;
    font-weight: 400;
    color: #104D73;
}
.bxtxt1amb{
    margin-left: 5%;
    font-family: 'Oswald';
    font-style: normal;
    font-size: 8vw;
    font-weight: 400;
    color: #104D73;
    margin-top: -7%;
}
.bxtxt2b{
    margin-top: -3.4%;
    margin-left: 5%;
    font-family: 'Oswald';
    font-style: normal;
    font-size: 3.8vw;
    font-weight: 400;
    color: #104D73;
}
.bxtxt1c{
    margin-left: 5%;
    font-family: 'Oswald';
    font-style: normal;
    font-size: 8vw;
    font-weight: 400;
    color: #104D73;
}
.bxtxt2d{
    margin-top: -3.4%;
    margin-left: 5%;
    font-family: 'Oswald';
    font-style: normal;
    font-size: 3.8vw;
    font-weight: 400;
    color: #104D73;
}
.phonefirstmb222{
    position: absolute;
    width: 25%;
    margin-left: 37%;
    margin-top: 5.5%;
    height: 1.75%;
}
}