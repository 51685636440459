.new_header_main{
    width: 80%;
    margin: auto;
    padding-top:10% ;
}
.row_nlan,.row_nlan{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.col_newa{
flex-basis: 45%;
}

.col_newa img{
    width: 100%;

}
.col_newa input{
    width: 100%;
    padding: 5px;
    margin-top: 14px;

}
::placeholder{
    color: #D7D7D7;
    ;
    font-family: Oswald;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;

}
.col_newa h1{
    font-family: Oswald;
font-size: 64px;
font-weight: 500;
line-height: 95px;
letter-spacing: 0em;
text-align: left;
color: #104E71;


}
.col_newa h3{

font-family: Oswald;
font-size: 32px;
font-weight: 500;
line-height: 47px;
letter-spacing: 0em;
text-align: left;
color: #104E71;

}
.col_newa button{
    font-family: Oswald;
font-size: 20px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: center;
width: 100%;
padding: 2px;
background: #104E71;
color: #fff;
border: 1px solid #104E71;
border-radius: 10px;
margin-top: 20px;
}
.mid_new{
     width: 80%;
     margin: auto;
}
.mg_land{
    margin-top: 330px;
    visibility: hidden;
}
.for2{
    display: none;
}
#tls{
    font-size: .9vw;
}
@media only screen and (max-width: 1200px) {
    #for{
        display: none;
    }
    .for2{
        display: block;
    }
    .new_header_main{
        width: 80%;
margin: auto;
        padding-top:10% ;
    }
    .row_nlan{
        flex-direction: column;
     
    }
  
    .col_newa input{
        width: 100%;
        padding: 5px;
        margin-top: 12px;
    }
    
    .col_newa img{
        width: 100%;
        display: none;
    
    }
}

@media only screen and (max-width: 750px) {
    #tls{
        font-size: 3vw;
    }
    .mg_land{
        margin-top: 1290px;
    }    
}
@media only screen and (max-width: 450px) {
    .mg_land{
        margin-top: 1100px;
    }    
}
@media only screen and (max-width: 350px) {
    .mg_land{
        margin-top: 850px;
    }    
}
@media only screen and (max-width: 250px) {
    .mg_land{
        margin-top: 950px;
    }    
}