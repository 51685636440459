.container__article{
    width: 80%;
    margin: auto;
    text-align: center;
}
.container__article h1{
    font-family: 'Oswald';
    font-size: 54px;
    margin-top: 3%;
    text-align: center;
    text-transform: uppercase;
    color: #104E71;


}
.container__article h2{
    font-family: 'Oswald';
    font-size: 35px;
    text-align: left;
    text-transform: uppercase;
    color: #104E71;
   font-weight: 600;
   margin-top: 15px;
   margin-bottom: 15px;

}
.container__article h3{
    font-family: 'Oswald';
    font-size: 22px;
    text-align: left;
    text-transform: uppercase;
    color: #104E71    ;
   font-weight: 500;
   margin-top: 15px;
   margin-bottom: 15px;


}
.container__article p{
    font-family: 'Oswald';
    font-size: 18px;
    text-align: left;
    font-weight: 400;
    color: #000000;
    line-height: 27px;
    margin-top: 1%;
    margin-bottom: 1%;
}
#p__text{
    font-family: 'Oswald';
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    color: #000000;
    line-height: 27px;

}
.container__article hr{
    width: 100%;
    border: 1px solid #077F8D;
    margin-top: 3%;
}
.container__article img{
    width: 100%;
    margin-top: 3%;
    margin-bottom: 3%;
}
.container__article ul li{
    font-family: 'Oswald';
    font-size: 18px;
    text-align: left;
    font-weight: 400;
    color: #000000;
    line-height: 27px;
    margin-left: 5%;
    margin-right: 5%;
}
table, th, td {
    border:1px solid black;
    font-size: 18px;
    font-family: 'Oswald';
    padding: 10px;

  }
@media only screen and (max-width: 750px) {
    .logodarkblue,.skylogo3{
        display: none;
    }
    .container__article h1{
        font-family: 'Oswald';
        font-size: 34px;
    }    
}