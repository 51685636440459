.resh1{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 6vw;
    text-transform: uppercase;
    color: #104E71;
    margin-left: 8%;
}
.resh2{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 3vw;
    text-transform: uppercase;
    color: #104E71;
    margin-left: 8%;
    margin-top: 3%;
}
.pdffile{
    width: 50%;
    margin: auto;
}

.colpdf a{
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
    color: #104E71;
text-align: center;

width: 100%;
}
.colpdf a:hover{
    color: #285a77;
}
.rowpdf{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.colpdf{
    flex-basis:40%;
         margin-top: 2%
}
.pdffile h2{
    font-family: 'Oswald';
    font-weight: 500;
    font-size:25px;
    text-transform: uppercase;
    color: #104E71;
text-align: center;
margin-top: 5%;
}
@media only screen and (max-width: 750px) {
    .rowpdf{
        flex-direction: column;

    }
    .colpdf a:hover{
        color: #285a77;
    }
}