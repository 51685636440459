@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

body{
    
    overflow-X: hidden;
}
html {
    overflow-x: hidden;
  }
  
  body {
    position: relative;
    overflow-x: hidden;

  }
.main{
margin-top: 0%;
float: left;
width: 100%;
}
.linenavbarafaudience{
width: 97%;
border: 2px solid  #104E71;
margin-top: 25%;
margin-bottom: -7%;
}
  
  .nbartext{
    position: relative;
    margin-top: 1.5%;
float: left;
margin-left: 3%;
color: #104E71;
    text-align: center;
    font-family: 'Oswald';
    font-size: 1.5vw;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none !important;
}

.nbartext::before{
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 2px;
    background-color: #155a81;
    transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  }
  
  @media (hover: hover) and (pointer: fine) {
    .nbartext:hover::before{
      left: 0;
      right: auto;
      width: 100%;
    }
  }
a:hover{
    color: #155a81 !important;
    text-decoration: none !important;
}

.nbartext2{
    position: relative;
    float: left;
    margin-top: 1.5%;
    margin-left: 8%;
    color: #104E71;
            text-align: center;
        font-family: 'Oswald';
        font-size: 1.5vw;
        font-weight: 500;
        cursor: pointer;
        text-decoration: none !important;
    }  
    .nbartext2::before{
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 2px;
        background-color: #104E71;
        transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
      }
      
      @media (hover: hover) and (pointer: fine) {
        .nbartext2:hover::before{
          left: 0;
          right: auto;
          width: 100%;
        }
      }
    .nbartext2contact1{
        float: left;
        margin-top: 3.1%;
        margin-left: 2%;
        color: #104E71;
                text-align: center;
            font-family: 'Oswald';
            font-size: 1.3vw;
            font-weight: 500;
            cursor: pointer;
            text-decoration: none !important;
            border: none;
            background-color: white;
        }
        .nbartext2contact2{
            float: left;
            margin-top: 3.1%;
            margin-left: 2%;
            color: #104E71;
                    text-align: center;
                font-family: 'Oswald';
                font-size: 1.3vw;
                font-weight: 500;
                cursor: pointer;
                text-decoration: none !important;
                border: none;
                background-color: white;
            }  
.circle1{
    width: 7%;
    margin-left: 3%;
 float: left;
}
.circle2{
float: right;
    width:8%;


}
.main2{
    clear: both;  display: block; position: relative; 
}
.allonetext{
    font-family: 'Oswald';
    font-weight: 400;
    color: #104D73;
    font-size: 4.5vw;
    margin-left: 8%;
    margin-top:21%;
}
.phonetext{
    font-family: 'Oswald';
    font-weight: 400;
    color: #88F2E8;
    font-size: 7.8vw;
    margin-left: 8%;

}
.prgtext{
    font-family: 'Oswald';
    font-weight: 300;
    color: #104D73;
    font-size: 1.5vw;
    margin-left: 8%;

}
.mainimage{
    float: right;
    width: 38%;
    margin-right: 2%;
    margin-top: -27%;
}
@media only screen and (max-width: 750px) {
    .main{
        margin-top: 0%;
        float: left;
        width: 100%;
        }
          
          .nbartext{
            font-size: 0vw;
        }
        .nbartext2,.nbartext2contact1{
                font-size: 0vw;
                display: none;
            }  
        
        .circle1{
            width: 12%;
            margin-left: 25%;
         float: left;
         height: .5%;
        }
        .circle2{
        float: right;
            width:15%;
            height: .5%;
        
        }
        .main2{
            clear: both;  display: block; position: relative; 
        }
        .allonetext{
            font-family: 'Oswald';
            font-weight: 500;
            color: #104D73;
            font-size: 8.5vw;
            margin-left: 8%;
        }
        .phonetext{
            font-family: 'Oswald';
            font-weight: 400;
            color: #88F2E8;
            font-size: 15vw;
            margin-left: 8%;
        }
        .prgtext{
            font-family: 'Oswald';
            font-weight: 400;
            color: #104E71;
             font-size: 5vw;
            margin-left: 8%;
        }
        .mainimage{
            float: right;
            width: 0%;
            margin-right: 0%;
            margin-top: 0%;
        }
        a:hover{
            color: #ffff !important;
            text-decoration: none !important;
        }
  }