@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');
body{
    overflow-x: hidden;

}
.contactheader{
    position: relative;
}
.threephones{
 width: 100%;
}
.rectangleblue{
    position: absolute;
    width: 108%;
   margin-left: -100%;
   opacity: 0.5;    
}
.elpseleft{
    position: absolute;
    margin-left: -13%;
    width: 13%;
}
.elpseright{
    position: absolute;
    margin-top: -4%;
    margin-left: -100%;
    width: 20%;
}
.gettouch{
    position: absolute;
   margin-top: -17%;
    margin-left:38.3%;
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 4.3vw;
    font-style: normal;
    text-align: center;
    color: #FFFFFF;
}
.querytext{
   font-family: 'Oswald';
    font-weight: 500;
    font-size: 2.3vw;
    font-style: normal;
    text-align: center;
   margin-top: 5%;
    color: #104E71;
 }
 .contactform{
    margin-top: 5%;
    
 }
 .leftsideform{
    float: left;
    margin-left: 5%;
    width: 45%;
 }
 .formtext1{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 1vw;
    font-style: normal;
    text-align: center;
   color: #AB98BA;
   margin-top: 5%;
 }
 .inp{
    width: 70%;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid rgba(171, 152, 186, 0.5);
    margin-top: -12%;
    padding: 1%;
 }
 
 
 .inp:hover{
    border: 1px solid rgba(171, 152, 186, 1);
    
  }
  input[type=text]:focus {
    border: 2px solid rgba(171, 152, 186, 1) !important;
    outline: none;

}
  
  
 .rightsideform{
    float: left;
    margin-left: 0%;
    width: 45%;
 }
 .formcontrol{

    margin-top: 7.5% !important;     
    background: #FFFFFF !important;
    border: 1px solid rgba(171, 152, 186, 0.5) !important;
    border-radius: 5px !important;
    padding: 1%;
    font-family: 'Oswald' !important;
    font-weight: 400 !important;
    font-size: 1.2vw !important;
    font-style: normal;
     color: #AB98BA !important;
     width: 100%;
}
.formcontrol:focus {
    border: 2px solid rgba(171, 152, 186, 1) !important;
    outline: none !important;

}
.formcontrol:hover{
    border: 2px solid rgba(171, 152, 186, 1);
    
  }
 
.placeholder{
    color: #AB98BA ;


}
.submitbtn{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 1.2vw;
    text-align: center;
    color: #FFFFFF;
    align-items: center;
    background: #104E71;
    border-radius: 10px;
    width: 10%;
    float: right;
    margin-right: 5%;
 padding:.8%;
 margin-top: 2%;
 cursor: pointer;

}
.contactlast{
    margin-top: 4%;
}
.conlastleft{
    float: left;
    width: 40%;

}
.conltext1{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 2vw;
    text-align: center;
    color: #104E71;
}
.address{
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 1.2vw;
    text-align: center;
    color: #104E71;
    text-transform: uppercase;

}
.address3{
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 1.2vw;
    text-align: center;
    color: #104E71;
    text-transform: uppercase;
  
}

hr.conline{
    border: 1px solid #104E71;
    width: 7%;
}
.conlastright{
    float: left;
    width: 60%;
}

.followtext{
    margin-left: 62.5%;
    font-family: 'Oswald';
    font-weight: 500;
    font-size:2vw;
    color: #104E71;
}
.mapcon{
    margin-left: 17%;
    width: 75%;
    height: 50vh;
}
.querytextmb{
    display: none;
}
@media (max-width:750px) {
    .querytext{
        display: none;
    }
    .querytextmb{
        font-family: 'Oswald';
         font-weight: 500;
         font-size: 5vw;
         font-style: normal;
         text-align: center;
        margin-top: 5%;
         color: #104E71;
         display: block;
      }
      .contactform{
         margin-top: 0%;
         
      }
      .leftsideform{
         float: left;
         margin-left: 5%;
         width: 100%;
      }
      .formtext1{
         font-family: 'Oswald';
         font-weight: 500;
         font-size: 2.5vw;
         font-style: normal;
         text-align: center;
        color: #AB98BA;
        margin-top: 5%;
      }
      .inp{
         width: 90%;
         background: #FFFFFF;
         border-radius: 5px;
         border: 1px solid rgba(171, 152, 186, 0.5);
         margin-top: -12%;
         padding: 1%;
      }
      .rightsideform{
         float: left;
         margin-left: 0%;
         width: 90%;
      }
      .formcontrol{
     
         margin-top: 7.5% !important;     
         background: #FFFFFF !important;
         border: 1px solid rgba(171, 152, 186, 0.5) !important;
         border-radius: 5px !important;
         margin-left: 5%;
         font-family: 'Oswald' !important;
         font-weight: 400 !important;
         font-size: 2.5vw !important;
         font-style: normal;
          color: #AB98BA !important;
     }
     .placeholder{
         color: #AB98BA ;
     
     }
     .submitbtn{
         font-family: 'Inter', sans-serif;
         font-weight: 500;
         font-size: 3vw;
         text-align: center;
         color: #FFFFFF;
         align-items: center;
         background: #104E71;
         border-radius: 10px;
         width: 25%;
         float: right;
         margin-right: 5%;
      padding:1.5%;
      margin-top: 4%;
     
     }
     .contactlast{
         margin-top: 4%;
     }
     .conlastleft{
         float: left;
         width: 100%;
     
     }
     .conltext1{
         font-family: 'Oswald';
         font-weight: 500;
         font-size: 5vw;
         text-align: center;
         color: #104E71;
     }
     .address{
         font-family: 'Oswald';
         font-weight: 400;
         font-size: 3vw;
         text-align: center;
         color: #104E71;
         margin-top: -2%;
         text-transform: uppercase;
         margin-bottom: -.1%;
     
     }
     .address3{
        font-family: 'Oswald';
        font-weight: 400;
        font-size: 3vw;
        text-align: center;
        color: #104E71;

        text-transform: uppercase;
    
    }
    
     hr.conline{
         border: 1px solid #104E71;
         width: 7%;
     }
     .conlastright{
         float: left;
         width: 100%;
     }
     
     .followtext{
         margin-left: 62.5%;
         font-family: 'Oswald';
         font-weight: 500;
         font-size:2vw;
         color: #104E71;
     }
     .mapcon{
        margin-left: 5%;
        width: 90%;
        height: 50vh;
        margin-top: 3%;
    }
}