.bpstxtc1{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 4.5vw;
    text-transform: uppercase;
    color: #104E71;
    margin-left: 55%;
    margin-top: 5%;
}
.bpstxtc2{
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 1.1vw;
    text-transform: uppercase;
    color: #104E71;
    margin-left: 62%;
    margin-top: 2.2%;
}
.bpscircle2{
    position: absolute;
    width: 30%;
    margin-top: 3%;
    margin-left: 10%;
}
.bpsskylogoc1{
    position: absolute;
    width: 27%;
    margin-top: 4.5%;
    margin-left: 11.6%;
}
.bpsman1{
    position: absolute;
    width: 25.5%;
    margin-top: 1.40%;
    margin-left: 12.3%;
    border-bottom-right-radius:45% ;
    border-bottom-left-radius:45% ;
}
.bpsm{
    position: absolute;
    margin-left: 55%;
    margin-top: 9.2%;
    width: 5%;
}
.bpslineh{
    position: absolute;
    margin-left: 57.5%;
    margin-top: 14.3%;
    width: .065%;
}
.bpsp{
    position: absolute;
    margin-left: 55.3%;
    margin-top: 16.4%;
    width: 4.7%;
}
.bpslineh2{
    position: absolute;
    margin-left: 57.5%;
    margin-top: 21.4%;
    width: .13%;
}
.bpsmsg{
    position: absolute;
    margin-left: 55.3%;
    margin-top: 25.5%;
    width: 4.7%;
}
#hid2mb{
display: none;
}
@media only screen and (max-width: 750px) {
    .bpstxtc1{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 8vw;
        text-transform: uppercase;
        color: #104E71;
        margin-left: 5%;
        margin-top: 5%;
    }
    .bpstxtc2{
        font-family: 'Oswald';
        font-weight: 400;
        font-size: 3vw;
        text-transform: uppercase;
        color: #104E71;
        margin-left: 18%;
        margin-top: 4.7%;
    }
    #hid2mb{
        font-family: 'Oswald';
        font-weight: 400;
        font-size: 3vw;
        text-transform: uppercase;
        color: #104E71;
        margin-left: 15%;
        margin-top: 4.7%;
        display: block;
    }
    .bpscircle2{
        position: relative;
        width: 70%;
        margin-top: 5%;
        margin-left: 15%;
    }
    .bpsskylogoc1{
        position: absolute;
        width: 60%;
        margin-top: 10%;
        margin-left: -65%;
    }
    .bpsman1{
        position: absolute;
        width: 58%;
        margin-top: 1.3%;
        margin-left: -63.7%;
        border-bottom-right-radius:45% ;
        border-bottom-left-radius:45% ;
    }
    .bpsm{
        position: absolute;
        margin-left: -80%;
        margin-top: 98.5%;
        width: 10%;
    }
    .bpslineh{
        position: absolute;
        margin-left: -75.2%;
        margin-top: 109.5%;
        width: .3%;
    }
    .bpsp{
        position: absolute;
        margin-left:-80%;
        margin-top: 119%;
        width: 10%;
    }
    .bpslineh2{
        position: absolute;
        margin-left: -75.2%;
        margin-top: 10.4%;
        width: .3%;
    }
    .bpsmsg{
        position: absolute;
        margin-left: -80%;
        margin-top: 140%;
        width: 10%;
    }
    #hid,#hid2{
display: none;
    }
}