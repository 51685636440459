
.product__item{
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(10, 116, 243, 0.25);
    border-radius: 6px;
    max-width: 370px;
    height: 880px;


}
.product__header{
    background: #104E71;
    text-align: center;
    padding: 15px;
}
.product__header h2{
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 44px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);

}
.product__header h3{
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    line-height: 44px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);

}
.product__circle{
    background: #104E71;
    border: 5px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius:50%;
    padding: 40px;
    max-width: 150.08px;
margin-left: 29%;
text-align: center;
    margin-top: -5%;
}
.product__circle h3{
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    line-height: 100%;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.product__circle h2{
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    margin-left: -22%;
    line-height: 100%;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}
.product__last{
    background: #FFFFFF;


}
.product__last table{
    width: 100%;
    border: none;
    background: #F5F5F5;

}

.product__last table tr td{
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
   color: #212121;



}
.product__last table tr:nth-child(odd){background:#F5F5F5}
.product__last table tr:nth-child(even){background:white}


.select__plan{
    margin-top: -25%;
    text-align: center;
    text-transform: capitalize;
    border: 1px solid #1E617A;
    border-radius: 50px;
    background: #FFFFFF;
    padding:18px;
    font-size: 15px;
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 700;
    color: #1E617A;
   margin-left: 4%;
   margin-right: 4%;

}
.select__plan:hover
{
    background: #1E617A;
    color: #FFFFFF;
}

@media only screen and (max-width: 1000px) {

.product__item{
    height: 1000px;
}
.select__plan{
    margin-top: -34%;
}
.product__circle h2{
    margin-left: -22%;

}

}
@media only screen and (max-width: 950px) {
    .product__circle{
         max-width: 130.08px;
    margin-left: 20%;
        margin-top: -5%;
    }

    .product__circle h2{
        margin-left: -80%;
      }
    
}
@media only screen and (max-width: 770px) {

    .product__item{
        height: 900px;
    }
    .select__plan{
        margin-top: -25%;
        width: 323px;
    
    } 
    .product__circle h2{
      margin-left: -80%;
    }
    
    
    
    }
@media only screen and (max-width: 750px) {
    .product__circle{
         max-width: 130.08px;
    margin-left: 33%;
        margin-top: -5%;
    }
}
@media only screen and (max-width: 370px) {

    .product__item{
        height: 900px;
    }
    .select__plan{
        margin-top: -25%;
        width: 90%;
    
    }   
    }
@media only screen and (max-width: 320px) {
    .product__circle{
         max-width: 130.08px;
    margin-left: 30%;
        margin-top: -5%;
    }
}

@media only screen and (max-width: 290px) {
    .product__circle{
         max-width: 130.08px;
    margin-left: 25%;
        margin-top: -5%;
    }
}

@media only screen and (max-width: 230px) {
    .product__circle{
         max-width: 130.08px;
    margin-left: 20%;
        margin-top: -5%;
    }
}
@media only screen and (max-width: 210px) {
    .product__circle{
         max-width: 130.08px;
    margin-left: 15%;
        margin-top: -5%;
    }
}