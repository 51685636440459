body{
    overflow-x: hidden;
}
.wvbtext1{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 3vw;
    text-transform: uppercase;
    color: #104E71;
    margin-left: 8%;
    margin-top: 8%;

}
.wvbtext2bb{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 6vw;
    text-transform: uppercase;
    color: #104E71;
    margin-left: 8%;


}
.wvbtext3{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 3vw;
    text-transform: uppercase;
    color: #104E71;
    margin-left: 8%;

}
.wvbtext4{
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 1.2vw;
    color: #000000;
    margin-left: 8%;

}
.line1wvb{
    margin-top: 10%;
    border: 1px solid #077F8D;
    width: 90%;
}
.wvbtext4mb{
    display: none;
}
@media only screen and (max-width: 750px) {
    .wvbtext1{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 6vw;
        text-transform: uppercase;
        color: #104E71;
        margin-left: 5%;
        margin-top: 5%;
    
    }
    .wvbtext2bb{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 12vw;
        text-transform: uppercase;
        color: #104E71;
        margin-left: 5%;

    
    }
    .wvbtext3{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 6vw;
        text-transform: uppercase;
        color: #104E71;
        margin-left: 5%;
    
    }
    .wvbtext4{
        display: none;
    }
    .wvbtext4mb{
        font-family: 'Oswald';
        font-weight: 400;
        font-size: 3.3vw;
        color: #000000;
        margin-left: 5%;
    display: block;
    }
    .line1wvb{
        margin-top: 10%;
        border: 1px solid #077F8D;
        width: 90%;
    }
}