.vtext1{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 40px;
    text-transform: uppercase;
    color: #104E71;
    margin-left: 8%;
    margin-top: 8%;

}
.vtext2{
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    max-width: 650px;
    margin-left: 8%;
   margin-bottom:20%;
}
.linevphone{
    border: 1px solid #077F8D;
    width: 90%;
}
.vtext3{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 40px;
    text-transform: uppercase;
    color: #104E71;
   margin-top: 2%;
   margin-bottom: 2%;
   text-align: center;
}

.voip__phones{
    width: 80%;
    margin: auto;
    margin-top: 5%;

}
.row__phone{
    display: flex;
    justify-content: space-between;
    padding-bottom: 5%;
}

.voip__phones__col{
    flex-basis: 47%;
}
.voip__bx{
width: 100%;
border-radius: 30px;
border: 2px solid #104E71;
padding: 20px;
display: flex;
justify-content: space-between;
align-items: center;

}
.voip__bx:hover{
    box-shadow: 0 0 20px 0px rgba(0,0,0,0.2);
}
.voip__bx__flex{
    flex-basis: 70%;
}
.voip__bx__flexb{
    flex-basis: 30%;
    text-align: center;
}
.voip__bx__flex h2{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 33px;
    text-transform: uppercase;
    color: #104E71;
 
}
.voip__bx__flex h3{
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-top: 5%; 
    line-height: 150%;
}

.voip__bx__flexb img{
    width: 100%;


}
.voip__bx__flexb h2{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 25px;
    color: #077F8D;
    margin-top: 5%;
    text-align: center; 
}
.voip__bx__flexb button{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 14px;
    margin-top: 5%;
    padding: 10px;
    background: #104E71;
    color: white;
    cursor: pointer;
    text-align: center;


    border-radius: 5px; 
    transition: 0.5s;
    border-color: none !important;
    border: none !important;
}
.voip__bx__flexb button:hover{
    border-color: none !important;
    border: none !important;
}

.modal-body{
    text-align: center !important;
}
.modal-body img{
    width: 25% !important;
}
.modal__list{
    width: 60% !important;
    margin: auto !important;
}
.modal__list h2{
    text-align: left !important;
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 30px;
    text-transform: uppercase;
    color: #104E71;

}
.modal__list h3{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 22px;
    color: #077F8D;
    margin-top: 5%;
    text-align: left;
}

.modal__list li{
    font-family: 'Oswald';
    font-weight: 400;
    color: #000000;
        margin-top: 1%;
    text-align: left;
    font-size: 17px;


}
.close{
    color: #000000 !important;
    font-size: 30px !important;
    outline: none !important;
    text-align: right !important;
    margin-right: 10% !important;
}

#contact__btn{
    margin-left: 0% !important;
margin-bottom: 3% !important;
}
#contact__btn a{
    color: #fff !important;
}
#contact__btn a:hover{
    color: #fff !important;
}
@media only screen and (max-width: 1290px) {
    .voip__bx__flex h2{
        font-size: 25px;
     
    }
    .voip__bx__flex h3{
        font-size: 13px;

    }
    .voip__bx__flexb button{
         font-size: 12px;
      }   
      .vtext2{
        max-width: 550px;
       }
}
@media only screen and (max-width: 950px) {
    .voip__bx__flex h2{
        font-size: 20px;
     
    }
    .voip__bx__flex h3{
        font-size: 11px;

    }
    .voip__bx__flexb button{
         font-size: 11px;
      } 
            .vtext2{
        max-width: 450px;
       }  

}
@media only screen and (max-width: 850px) {
    .voip__bx__flex h2{
        font-size: 18px;
     
    }
    .voip__bx__flex h3{
        font-size: 9px;

    }
    .voip__bx__flexb button{
         font-size: 9px;
      }   
      .vtext2{
        max-width: 350px;
       }
}
@media only screen and (max-width: 750px) {
    .close{
        color: #000000 !important;
        font-size: 45px !important;
        outline: none !important;
        text-align: left !important;
        margin-right: 10% !important;
        margin-left: 80% !important;
    }
    .vtext2{
        text-align: left;
        max-width: 500px;
        font-size: 20px;
        margin-right: 10%;
       }
       .vtext3{
        font-size: 24px;
        }
    .row__phone{
        padding-bottom: 0%;
    }
    .voip__bx{
        margin-bottom: 5%;
    }
    .voip__bx__flex h2{
        font-size: 28px;
     
    }
    .voip__bx__flex h3{
        font-size: 20px;

    }
    .voip__bx__flexb button{
         font-size: 15px;
         margin-left: 24%;
      }
      .row__phone{
        flex-direction: column;

    }
    .modal-body img{
        width: 50% !important;
    }
    .voip__bx__flexb img{
        width: 100%;
        height: auto;
    }
}
@media only screen and (max-width: 570px) {
    .voip__bx{
        flex-direction: column;
        text-align: center;
        }
        .voip__bx__flexb img{
              }
        .voip__bx__flexb h2{
         }   
         .voip__bx__flexb button{
            font-size: 20px;
            width: 100%;
            margin-left: 0%;
         }   


}