.headermain{
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.12);
    width: 100%;
float: left;
padding-bottom: 1%;
color: white;


}
.headerlogo{
    float: left;
    width: 8.5%;
margin-left: 8%;
margin-top: 2%;
}
.loginbtn{
float: left;
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    font-size: 1.2vw;
    color: #104E71;
    margin-top: 4.6%;
    margin-left: 54%;
}
.gstartbtn{
    float: left;
    width: 10%;
    font-family: 'Oswald';
    font-weight: 500;
    text-align: center;
    color: #FFFFFF;
    font-size: 1.2vw;
    margin-top: 4.2%;
    margin-left: 5%;
    background-color: #104D73;
    border-radius: 5px;
    cursor: pointer;
    padding: .5%;
}
.pnumber{
  font-family: 'Oswald';
  margin-top: 4%;
  float: right;
  margin-right: 8%;
  color: #145070;
}
@media only screen and (max-width: 750px) {
  .headermain{
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.12);
      width: 0%;
  float: left;
padding-bottom: 0%;
  color: white;
  
  
}
.headerlogo{
    float: none;
    width: 0%;
margin-left: 0%;
margin-top: 0%;
}
.loginbtn{
float: none;
    font-family: 'Oswald', sans-serif;
    font-weight: 0;
    font-size: 0vw;
    color: #104E71;
    margin-top: 0%;
    margin-left: 0%;
}
.gstartbtn{
    float: none;
    width: 0%;
    font-family: 'Oswald';
    font-weight: 0;
    text-align: center;
    color: #FFFFFF;
    font-size: 0vw;
    margin-top: 0%;
    margin-left: 0%;
    background-color: #104D73;
    border-radius: 0px;
    cursor: pointer;
    padding: 0%;
}
.pnumber{
  font-family: 'Oswald';
  margin-top: 0%;
  float: right;
  margin-right: 0%;
  font-size: 0px;
  color: #145070;
}
}