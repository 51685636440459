.footer__section{
    width: 100%;
    background: #F7F7F7;
    margin-top: 5%;
    color: #61616d;

}
.footer__width{
    width: 80%;
    margin: auto;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
  
  font-family: 'Oswald';
  
}
.footer__row{
    display: flex;
    justify-content: space-between;
}

.footer_colb{
    flex-basis:40%;
    padding: 20px 12px;
    box-sizing: border-box;
    text-align: left;
 }
 .footer_colb img{
    width: 25%;
 }
 .footer_col{
    flex-basis:15%;
    padding: 20px 12px;
    box-sizing: border-box;
    text-align: left;

 }
.footer_col h3,.footer_colb h3{
    
    font-family: 'Oswald';
    font-size: 18px;
    font-weight: 700;
}
.footer_colb p,.footer_colb p{
    margin-top: 5%;
    font-family: 'Oswald';
    font-size: 14px;
    font-weight: 300;
    
}

.footer_col a{
    color: #888889;  
    margin-top: 5%;
    font-family: 'Oswald';
}
.link__footer{
    color: #888889;
    font-family: 'Oswald';
    font-weight: 300;
    font-size: 14px;
    display: block;
    margin-top: 5%; 
}
.contact_detil{
    display: flex;
    align-items:baseline;
    margin-top: 5%;
}
.contact_detil i{
    font-size: 18px;
    color: #888889;
    font-weight: 300;  
 
}
.contact_detil span {
    font-family: 'Oswald';
        font-size: 14px;
        color: #888889;
    font-weight: 300;
    margin-left: 4%; 
    margin-top: 12%;  
}
.contact_detil a {
    font-family: 'Oswald';
    color: #888889;    font-weight: 300;
     
}
.social__media{
    margin-top: 20%;
}
.social__media i{
    font-size: 20px;
    color: #888889;    font-weight: 300;
    padding-right: 14px;
   
}
@media (max-width:750px) {
 .footer__row{
    flex-direction: column;
 }   
 .contact_detil span {
    font-family: 'Oswald';
        font-size: 14px;
        color: #888889;   font-weight: 300;
    margin-left: 4%; 
    margin-top: 5%;  
}
.social__media{
    margin-top: 10%;
}
.social__media i{
    font-size: 35px;
    color: #888889;    font-weight: 300;
    padding-right: 35px;
   padding-top: 20px;
}
}